import React from 'react';

import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';
import { FaTshirt, FaClock, FaCalendarDay, FaChild, FaHeart, FaFlag } from 'react-icons/fa';

const IndexPage = () => (
  <Layout fullMenu subPage>
    <Seo
      description=""
      keywords=""
      title="Rodinný a svatební fotograf beskydy"
    />
    <article id="main">
      <header id='info-photo' className='sub-page'>
        <StaticImage
          src='../assets/images/pic01.jpg' alt='A kitten'
          placeholder='blurred'
          objectFit='cover'
          className='static-image'
          objectPosition='50% 0%'
        />
        <div className='header-text'>
          <h1>Jak probíhá focení?</h1>
          <p>
            Sejdeme se v lese, v parku, na hřišti či na vašem oblíbeném místě a
            formou procházky a řádění s dětmi se nám podaří vytvořit přirozené
            snímky,
            <br />
            které budou odrazem vás samých.
            <br />
            <br />
            Vždy je mým cílem zachytit rodinu takovou, jaká je. Nebudu vás nutit
            do křečovitých póz.
          </p>
        </div>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <section id="three" className="style3 special">
            <div className="inner">
              <ul className="features">
                <li className="solid">
                  <h3><FaTshirt /> Jaké oblečení?</h3>
                  <p>
                    Nejdůležitější je vaše pohodlí. Vemte si proto zrátka co
                    máte rádi. Oblečení je dobré co nejlépe barevně zkombinovat,
                    abyste k sobě ladili. Barvy je lépe volit tlumené.
                    Doporučuji se vyvarovat nápisů. Pokud máte klobouk,
                    neváhejte.
                  </p>
                </li>
                <li>
                  <h3><FaClock />Jaký je vhodný čas?</h3>
                  <p>
                    Focení je nejlépe naplánovat ráno či navečer, kdy již není
                    ostré světlo, ale naopak je velmi lichotivé.
                  </p>
                </li>
                <li>
                  <h3><FaCalendarDay />Kdy se můžete těšit na fotografie?</h3>
                  <p>
                    Fotografie většinou zasílám do dvou týdnů od jejich
                    pořízení.
                  </p>
                </li>
                <li>
                  <h3><FaChild />Focení s dětmi?</h3>
                  <p>
                    Při focení dětí doporučuji s sebou vzít oblíbenou hračku a
                    malý úplatek.;)
                  </p>
                </li>
                <li>
                  <h3><FaHeart />Focení miminek</h3>
                  <p>
                    Pro fotografování těch úplně nejmenších miminek je nejlepší se sejít u vás doma, kde zachytíme ideální kouzlo rodinné atmosféry.
                  </p>
                </li>
                <li>
                  <h3><FaFlag />Kde fotím?</h3>
                  <p>
                    Fotím především v okolí malebných Beskyd, po domluvě je možné focení
                    uskutečnit v širším okolí. Dále se pravidelně vyskytuji ve Znojmě, Ostravě nebo v Praze.
                  </p>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
